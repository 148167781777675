/* eslint-disable */

import React, { FC, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { InvestorRibbonProps } from "../generated-types";
import { eurolandIframeResizer } from "~/foundation/Utils/iframeResizer";
import { themable } from "~/foundation/Theme";
import LazyLoad from 'react-lazy-load';

const InvestorRibbon: FC<InvestorRibbonProps> = ({ fields }) => {
	const iframe1Ref = React.createRef<HTMLIFrameElement>();
	const iframe2Ref = React.createRef<HTMLIFrameElement>();
	const iframe3Ref = React.createRef<HTMLIFrameElement>();

	const adjustIframeHeight = (iframeElement: HTMLIFrameElement | null) => {
		if (iframeElement && fields?.adjustIframeHeight?.value) {
			eurolandIframeResizer(iframeElement, false);
		}
	}

	const iframeSrcCount = fields?.url2?.value && !fields?.url3?.value ? 2 : fields?.url3?.value ? 3 : 1;

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]} maxW={[null, null, "contentWrapperWidthMD", "contentWrapperWidthLG", "contentWrapperWidthXL", null]}>
				<GenericHeader {...fields} />
			</ContentWrapper>
			<ContentWrapper py="0" maxW={[null, null, "contentWrapperWidthMD", "contentWrapperWidthLG", "contentWrapperWidthXL", null]}>
				<Flex
					justifyContent="space-around"
					flexBasis={["100%", null, "auto"]}
					flexWrap={["wrap"]}
					gap={["6"]}>
					{fields?.url1?.value && (
						<Box
							flexGrow="1"
							minW="330px"
							maxW={iframeSrcCount === 3 ? "330px" : iframeSrcCount === 2 ? "50%" : "100%"}
							borderRadius="lg"
							overflow="hidden">
							<LazyLoad offset={500} onContentVisible={() => adjustIframeHeight(iframe1Ref.current)}>
								<Box as="iframe" ref={iframe1Ref} title={fields.title1?.value} src={fields.url1.value} h="490" w="100%" />
							</LazyLoad>
						</Box>
					)}
					{fields?.url2?.value && (
						<Box
							flexGrow="1"
							minW="330px"
							maxW={iframeSrcCount === 3 ? "330px" : "50%"}
							borderRadius="lg"
							overflow="hidden">
							<LazyLoad offset={500} onContentVisible={() => adjustIframeHeight(iframe2Ref.current)}>
								<Box as="iframe" ref={iframe2Ref} title={fields.title2?.value} src={fields.url2.value} h="490" w="100%" />
							</LazyLoad>
						</Box>
					)}
					{fields?.url3?.value && (
						<Box
							flexShrink="1"
							flexGrow="1"
							maxW="510px"
							minW={["100%","330px"]}
							borderRadius="lg"
							overflow="hidden">
							<LazyLoad offset={500} onContentVisible={() => adjustIframeHeight(iframe3Ref.current)}>
								<Box as="iframe" ref={iframe3Ref} title={fields.title3?.value} src={fields.url3.value} h="490" w="100%" />
							</LazyLoad>
						</Box>
					)}
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(InvestorRibbon);