/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeepPartial } from "utility-types";

type IframeMessage = {
	iFrame: {
		height: number;
		index: number;
	}
};

const iframeArr: HTMLIFrameElement[] = [];

export const eurolandIframeResizer = (iframe: HTMLIFrameElement, isHeightToParent: boolean) => {

	let activationMessage = "ActivateEurolandToolAutoSizeObject-" + iframeArr.length;
	if (isHeightToParent) {
		activationMessage += "-1";
	}

	iframeArr.push(iframe)

	iframeResizer<IframeMessage>(iframe, activationMessage, (data) => {
		if (data) {
			if (data.iFrame && typeof data.iFrame.height === "number" && typeof data.iFrame.index === "number") {
				const iframeItem = iframeArr[data.iFrame.index];
				if (iframeItem) {
					iframeItem.style.height = data.iFrame.height + "px";
				}
			}
		}
	})
}


export const iframeResizer = <T extends object>(iframe: HTMLIFrameElement, activationMessage: string, callback: (data: DeepPartial<T> | null) => void) => {
	window.addEventListener("message", (e) => {
		let obj: DeepPartial<T> | null = null;
		try {
			obj = JSON.parse(e.data) as DeepPartial<T>;
		} catch (e) {
			return;
		}

		callback(obj);
	});

	iframe.width = "100%";
	iframe.style.background = 'transparent';
	iframe.setAttribute("scrolling", "no");

	iframe.style.minWidth = "100%";
	iframe.style.width = "1px";
	iframe.style.maxHeight = "none";
	iframe.style.minHeight = "0px";

	//sends the activation message to the tool
	iframe.contentWindow?.postMessage(activationMessage, "*");

	//just to be safe sends the activation message to the Tool again onload
	iframe.onload = () => {
		iframe.contentWindow?.postMessage(activationMessage, "*");
	}
}
